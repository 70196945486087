import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

function Image({ fluid, fixed, fallback, alt, ...other }) {
  /* eslint-disable react/jsx-props-no-spreading */
  if (fluid || fixed) {
    return (
      <Img fluid={fluid} fixed={fixed} alt={alt || fallback.alt} {...other} />
    );
  }

  return <img src={fallback.url} alt={fallback.alt || alt} {...other} />;
}

Image.propTypes = {
  fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fallback: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  alt: PropTypes.string,
};

Image.defaultProps = {
  fluid: null,
  fixed: null,
  fallback: {},
  alt: '',
};

export default Image;
